'use client'

import * as React from 'react'
import { Toaster as SonnerToaster } from 'sonner'

import { cn } from '@/utils/ui'

type ToasterProps = React.ComponentProps<typeof SonnerToaster>

const Toaster = ({ className, ...props }: ToasterProps) => {
  return (
    <SonnerToaster
      className={cn(className)}
      toastOptions={{
        classNames: {
          toast: cn(
            'group border-border bg-background text-foreground',
            'data-[type=success]:border-green-500 data-[type=success]:text-green-500',
            'data-[type=error]:border-destructive data-[type=error]:text-destructive',
            'data-[type=info]:border-blue-500 data-[type=info]:text-blue-500',
            'data-[type=warning]:border-yellow-500 data-[type=warning]:text-yellow-500',
          ),
          title: 'text-foreground',
          description: 'text-muted-foreground text-xs',
          actionButton: 'bg-primary text-primary-foreground',
          cancelButton: 'bg-muted',
          closeButton: 'text-foreground/50 hover:text-foreground',
        },
      }}
      {...props}
    />
  )
}

export { Toaster }
