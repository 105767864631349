'use client'

import { cn } from '@/utils/ui'
import * as LabelPrimitive from '@radix-ui/react-label'
import { cva } from 'class-variance-authority'
import * as React from 'react'

/**
 * @file Label Component
 * @description A form label component that provides semantic labeling for form controls.
 * Built with Radix UI and Tailwind CSS, with accessibility in mind and supports various styling options.
 *
 * @see LabelProps for a list of available props
 *
 * @example
 * // Basic usage
 * <Label>Username</Label>
 *
 * @example
 * // With form control
 * <Label>
 *   Email address
 *   <Input type="email" />
 * </Label>
 *
 * @example
 * // Required field
 * <Label required>
 *   Password
 *   <Input type="password" />
 * </Label>
 */

/**
 * @interface LabelProps
 * @description Defines the props for the Label component.
 * @extends React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
 *
 * @property {string} [className] - Additional CSS classes to apply to the label.
 */
export interface LabelProps extends React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> {
  className?: string
}

const labelVariants = cva(
  'text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
  {
    variants: {},
    defaultVariants: {},
  },
)

const Label = React.forwardRef<React.ElementRef<typeof LabelPrimitive.Root>, LabelProps>(
  ({ className, ...props }, ref) => (
    <LabelPrimitive.Root className={cn(labelVariants({ className }))} ref={ref} {...props} />
  ),
)
Label.displayName = LabelPrimitive.Root.displayName

export { Label }
