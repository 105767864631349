'use client'

import { cn } from '@/utils/ui'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { Check } from 'lucide-react'
import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

/**
 * @file Checkbox Component
 * @description A form control component that allows users to select one or multiple options.
 * Built with Radix UI and Tailwind CSS, supporting various states and styles.
 *
 * @see CheckboxProps for a list of available props
 *
 * @example
 * // Basic usage
 * <Checkbox>Accept terms</Checkbox>
 *
 * @example
 * // Controlled
 * <Checkbox
 *   checked={isChecked}
 *   onCheckedChange={setIsChecked}
 * >
 *   Subscribe to newsletter
 * </Checkbox>
 *
 * @example
 * // Indeterminate
 * <Checkbox checked="indeterminate">
 *   Select all
 * </Checkbox>
 *
 * @example
 * // Disabled
 * <Checkbox disabled>
 *   Unavailable option
 * </Checkbox>
 *
 * @example
 * // With variants and sizes
 * <Checkbox variant="accent" size="lg">
 *   Large accent checkbox
 * </Checkbox>
 */

/**
 * @const checkboxVariants
 * @description Defines the style variants for the Checkbox component using `class-variance-authority`.
 *
 * @variants
 * - `variant`: Defines the visual style of the checkbox.
 *   - `default`: The standard checkbox style.
 *   - `outline`: A bordered checkbox style.
 *   - `accent`: A checkbox with accent color.
 * - `size`: Defines the size of the checkbox.
 *   - `default`: Standard size.
 *   - `sm`: Small size.
 *   - `lg`: Large size.
 */
const checkboxVariants = cva(
  'peer border ring-offset-background focus-visible:ring-ring focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 shrink-0 rounded',
  {
    variants: {
      variant: {
        default:
          'border-primary data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground',
        outline:
          'border-neutral-300 data-[state=checked]:border-neutral-900 data-[state=checked]:bg-white data-[state=checked]:text-neutral-900',
        accent:
          'border-[oklch(85%_0.07_80)] data-[state=checked]:bg-[oklch(71.5%_0.15_80)] data-[state=checked]:text-white',
      },
      size: {
        default: 'h-4 w-4',
        sm: 'h-3 w-3',
        lg: 'h-5 w-5',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

/**
 * @interface CheckboxProps
 * @description Defines the props for the Checkbox component.
 * @extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
 * @extends VariantProps<typeof checkboxVariants>
 *
 * @property {string} [className] - Additional CSS classes to apply to the checkbox.
 * @property {string} [variant] - The visual style variant of the checkbox.
 * @property {string} [size] - The size of the checkbox.
 */
export interface CheckboxProps
  extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
    VariantProps<typeof checkboxVariants> {
  className?: string
}

const Checkbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps>(
  ({ className, variant, size, ...props }, ref) => (
    <CheckboxPrimitive.Root
      className={cn(checkboxVariants({ variant, size, className }))}
      ref={ref}
      {...props}
    >
      <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center text-current')}>
        <Check
          className={cn(
            size === 'sm' ? 'h-3 w-3' : '',
            size === 'default' ? 'h-4 w-4' : '',
            size === 'lg' ? 'h-5 w-5' : '',
            'h-4 w-4',
          )}
        />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  ),
)
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox, checkboxVariants }
