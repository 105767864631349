'use client'

import { cn } from '@/utils/ui'
import * as React from 'react'
import { cva } from 'class-variance-authority'

/**
 * @file Input Component
 * @description A form input component that provides a consistent interface for text input.
 * Supports various types and states.
 *
 * @see InputProps for a list of available props
 *
 * @example
 * // Basic usage
 * <Input placeholder="Enter text" />
 *
 * @example
 * // With type
 * <Input type="email" placeholder="Email address" />
 *
 * @example
 * // With label
 * <Label>
 *   Username
 *   <Input placeholder="Enter username" />
 * </Label>
 */

/**
 * @interface InputProps
 * @description Defines the props for the Input component.
 * @extends React.InputHTMLAttributes<HTMLInputElement>
 *
 * @property {string} [className] - Additional CSS classes to apply to the input.
 */
export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string
}

const inputVariants = cva(
  'border-border bg-background ring-offset-background placeholder:text-muted-foreground focus-visible:ring-ring flex h-10 w-full rounded border px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {},
    defaultVariants: {},
  },
)

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return <input className={cn(inputVariants({ className }))} ref={ref} type={type} {...props} />
  },
)
Input.displayName = 'Input'

export { Input }
