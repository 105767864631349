'use client'

import { cn } from '@/utils/ui'
import * as SelectPrimitive from '@radix-ui/react-select'
import { Check, ChevronDown, ChevronUp } from 'lucide-react'
import * as React from 'react'
import { cva } from 'class-variance-authority'

const Select = SelectPrimitive.Root

const SelectGroup = SelectPrimitive.Group

const SelectValue = SelectPrimitive.Value

/**
 * @interface SelectTriggerProps
 * @description Defines the props for the SelectTrigger component.
 * @extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
 *
 * @property {string} [className] - Optional CSS class name for the component.
 */
interface SelectTriggerProps
  extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> {
  className?: string
}

const selectTriggerVariants = cva(
  'border-input bg-background ring-offset-background placeholder:text-muted-foreground focus:ring-ring flex h-10 w-full items-center justify-between rounded border px-3 py-2 text-inherit focus:ring-2 focus:ring-offset-2 focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1',
  {
    variants: {},
    defaultVariants: {},
  },
)

/**
 * @const SelectTrigger
 * @description A styled trigger component for the select.
 */
const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  SelectTriggerProps
>(({ children, className, ...props }, ref) => (
  <SelectPrimitive.Trigger
    className={cn(selectTriggerVariants({ className }))}
    ref={ref}
    {...props}
  >
    {children}
    <SelectPrimitive.Icon asChild>
      <ChevronDown className="h-4 w-4 opacity-50" />
    </SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
))
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName

/**
 * @interface SelectScrollUpButtonProps
 * @description Defines the props for the SelectScrollUpButton component.
 * @extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>
 *
 * @property {string} [className] - Optional CSS class name for the component.
 */
interface SelectScrollUpButtonProps
  extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton> {
  className?: string
}

const selectScrollUpButtonVariants = cva('flex cursor-default items-center justify-center py-1', {
  variants: {},
  defaultVariants: {},
})

/**
 * @const SelectScrollUpButton
 * @description A styled scroll up button component for the select.
 */
const SelectScrollUpButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
  SelectScrollUpButtonProps
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollUpButton
    className={cn(selectScrollUpButtonVariants({ className }))}
    ref={ref}
    {...props}
  >
    <ChevronUp className="h-4 w-4" />
  </SelectPrimitive.ScrollUpButton>
))
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName

/**
 * @interface SelectScrollDownButtonProps
 * @description Defines the props for the SelectScrollDownButton component.
 * @extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>
 *
 * @property {string} [className] - Optional CSS class name for the component.
 */
interface SelectScrollDownButtonProps
  extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton> {
  className?: string
}

const selectScrollDownButtonVariants = cva('flex cursor-default items-center justify-center py-1', {
  variants: {},
  defaultVariants: {},
})

/**
 * @const SelectScrollDownButton
 * @description A styled scroll down button component for the select.
 */
const SelectScrollDownButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
  SelectScrollDownButtonProps
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollDownButton
    className={cn(selectScrollDownButtonVariants({ className }))}
    ref={ref}
    {...props}
  >
    <ChevronDown className="h-4 w-4" />
  </SelectPrimitive.ScrollDownButton>
))
SelectScrollDownButton.displayName = SelectPrimitive.ScrollDownButton.displayName

/**
 * @interface SelectContentProps
 * @description Defines the props for the SelectContent component.
 * @extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
 *
 * @property {string} [className] - Optional CSS class name for the component.
 * @property {string} [position] - Optional position for the component.
 */
interface SelectContentProps
  extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content> {
  className?: string
  position?: 'popper' | 'item-aligned'
}

const selectContentVariants = cva(
  'bg-card text-popover-foreground data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 relative z-50 max-h-96 min-w-[8rem] overflow-hidden rounded border shadow-md',
  {
    variants: {
      position: {
        popper:
          'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
        'item-aligned': '',
      },
    },
    defaultVariants: {
      position: 'popper',
    },
  },
)

// Styled Select Content component
const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  SelectContentProps
>(({ children, className, position, ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      className={cn(selectContentVariants({ position, className }))}
      position={position}
      ref={ref}
      {...props}
    >
      <SelectScrollUpButton />
      <SelectPrimitive.Viewport
        className={cn(
          'p-1',
          position === 'popper' &&
            'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]',
        )}
      >
        {children}
      </SelectPrimitive.Viewport>
      <SelectScrollDownButton />
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
))
SelectContent.displayName = SelectPrimitive.Content.displayName

/**
 * @interface SelectLabelProps
 * @description Defines the props for the SelectLabel component.
 * @extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
 *
 * @property {string} [className] - Optional CSS class name for the component.
 */
interface SelectLabelProps extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label> {
  className?: string
}

const selectLabelVariants = cva('py-1.5 pr-2 pl-8 text-sm font-semibold', {
  variants: {},
  defaultVariants: {},
})

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  SelectLabelProps
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label className={cn(selectLabelVariants({ className }))} ref={ref} {...props} />
))
SelectLabel.displayName = SelectPrimitive.Label.displayName

/**
 * @interface SelectItemProps
 * @description Defines the props for the SelectItem component.
 * @extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
 *
 * @property {string} [className] - Optional CSS class name for the component.
 */
interface SelectItemProps extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item> {
  className?: string
}

const selectItemVariants = cva(
  'focus:bg-accent focus:text-accent-foreground relative flex w-full cursor-default items-center rounded py-1.5 pr-2 pl-8 text-sm outline-none select-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
  {
    variants: {},
    defaultVariants: {},
  },
)

// Styled Select Item component
const SelectItem = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Item>, SelectItemProps>(
  ({ children, className, ...props }, ref) => (
    <SelectPrimitive.Item className={cn(selectItemVariants({ className }))} ref={ref} {...props}>
      <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
        <SelectPrimitive.ItemIndicator>
          <Check className="h-4 w-4" />
        </SelectPrimitive.ItemIndicator>
      </span>

      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
  ),
)
SelectItem.displayName = SelectPrimitive.Item.displayName

/**
 * @interface SelectSeparatorProps
 * @description Defines the props for the SelectSeparator component.
 * @extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
 *
 * @property {string} [className] - Optional CSS class name for the component.
 */
interface SelectSeparatorProps
  extends React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator> {
  className?: string
}

const selectSeparatorVariants = cva('bg-muted -mx-1 my-1 h-px', {
  variants: {},
  defaultVariants: {},
})

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  SelectSeparatorProps
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    className={cn(selectSeparatorVariants({ className }))}
    ref={ref}
    {...props}
  />
))
SelectSeparator.displayName = SelectPrimitive.Separator.displayName

export {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectScrollDownButton,
  SelectScrollUpButton,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
}
